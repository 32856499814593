
import React from "react"
import PropTypes from "prop-types"

import "./layout.sass"
import Header from "./header"

const Layout = ({children}) => {
    return (
        <>
            <Header />
            <main className="main-content">{children}</main>
            <footer></footer>
        </>
    )
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout
