import {Link} from "gatsby"
import React from "react"
import "./header.sass"

const Header = () => (
    <header>
        <div className="navbar">
            <h1 className="title"><Link to="/"> Borian<span>Brückner</span></Link></h1>
            <ul className="nav">
                <li><Link to="/about">about</Link></li>
                {/*<li><Link to="/blog">blog</Link></li>*/}
            </ul>
        </div>
    </header>
);

export default Header
