import React from "react"
import Layout from "../components/layout"
import { MDXRenderer } from "gatsby-plugin-mdx"

const Post = ({
  data: {
    post,
    site: {
      siteMetadata: { title },
    },
  },
  location,
  previous,
  next,
}) => (
  <Layout location={location} title={title}>
    {/*<SEO title={post.title} description={post.excerpt} />*/}
    <main>
      <time>{post.date}</time>
      <h1>{post.title}</h1>
      <MDXRenderer>{post.body}</MDXRenderer>
    </main>
  </Layout>
);

export default Post
